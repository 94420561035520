import deepEqual from "deep-equal";
import { useState, useRef, useEffect } from "react";
import { Form as FinalForm } from "react-final-form";
import styled, { css, down, up } from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { EditButton } from "../../../components/FormCard";
import { FormStages } from "../index";

import { DisplayWorkExperience } from "./DisplayWorkExperience";
import { EditingWorkExperience } from "./EditingWorkExperience";
import { WorkExperienceItem } from "./FormCardList";
import { SuggestedChanges } from "./SuggestedChanges";

import { palette, pxToRem } from "@otta/design-tokens";
import { Card as DefaultCard, Button, Spacing, Text } from "@otta/design";
import { DeleteButton as DefaultDeleteButton } from "@otta/search/pages/Profile/components/DeleteButton";
import { Delete as Cancel } from "@otta/search/components/Icons/Delete";
import {
  CreateWorkExperienceSuggestionDocument,
  UserWorkExperienceInput,
  WorkExperienceSuggestionDocument,
} from "@otta/search/schema";

const CloseButton = styled(EditButton)`
  margin-left: auto;
`;

const Card = styled(DefaultCard)<{
  clickable: boolean;
  editing: boolean;
  showPreview: boolean;
}>(
  ({ clickable, editing, showPreview }) => css`
    ${clickable ? "cursor: pointer;" : null}
    ${showPreview && `border-radius: ${pxToRem(4)} ${pxToRem(8)} 0 0;`}

  margin: 0;
    padding-top: ${clickable ? pxToRem(8) : pxToRem(16)};
    border: ${pxToRem(1)} solid ${palette.brand.white};

    ${up(
      "desktop",
      css`
        #edit-button {
          visibility: hidden;
        }
        &:hover {
          #edit-button {
            visibility: visible;
          }

          ${!editing &&
          css`
            background: ${palette.beige.shade100};
            border: ${pxToRem(1)} solid ${palette.grayscale.shade400};
            cursor: pointer;
          `}
        }
      `
    )}
  `
);

const CardWithBorder = styled(DefaultCard)`
  border: ${pxToRem(1)} solid ${palette.grayscale.shade400};
`;

const PreviewCard = styled(DefaultCard)`
  background-color: yellow-100;
  margin-top: 0;
  border-radius: 0 0 4 4;
`;

const TopSection = styled.div<{ editing?: boolean }>`
  display: flex;
  justify-content: ${({ editing }) => (editing ? "space-between" : "flex-end")};
  width: 100%;
  margin-bottom: 0;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;

  ${up(
    "tablet",
    css`
      flex-direction: row-reverse;
    `
  )}
  width: 100%;
  justify-content: space-between;

  button:only-child {
    margin-left: auto;
  }

  ${down(
    "tablet",
    css`
      flex-direction: column;
    `
  )}
`;

const EditingComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0;
`;

interface IFile {
  path: string | null;
}

export interface EditedWorkExperienceItem {
  companyDescription: string | null;
  companyLogoPath: IFile[] | null;
  companyName: string;
  companyUrl: string | null;
  descriptions: string[];
  endDate: string | null;
  id: string;
  sectorTags: {
    id: string;
    value: string;
  }[];
  startDate: string;
  technologiesUsed: {
    id: string;
    value: string;
  }[];
  title: string;
}

const stripPath = (path: string): string =>
  path.replace("https://static.otta.com/uploads/images/", "");

const getFirstFile = (files: IFile[] | null): string | null => {
  if (files && files.length > 0) {
    return files[0].path;
  }
  return null;
};

const formatIncomingData = (experience: WorkExperienceItem) => {
  return {
    ...experience,
    companyLogoPath: experience.companyLogoPath
      ? [{ path: experience.companyLogoPath }]
      : [],
    descriptions: experience.descriptions.map(({ value }) => value),
    technologiesUsed: experience.technologiesUsed,
  };
};

const formatOutgoingData = ({
  title,
  companyName,
  companyDescription,
  companyUrl,
  companyLogoPath,
  startDate,
  endDate,
  descriptions,
  technologiesUsed,
  sectorTags,
}: EditedWorkExperienceItem): UserWorkExperienceInput => {
  return {
    title,
    companyName,
    companyDescription: companyDescription ?? null,
    companyUrl: companyUrl ?? null,
    companyLogoPath: getFirstFile(companyLogoPath)
      ? stripPath(getFirstFile(companyLogoPath) as string)
      : null,
    startDate,
    endDate: endDate ?? null,
    descriptions: (descriptions ?? [])
      .filter(x => x.length > 0)
      .map(value => ({ value })),
    technologiesUsed: (technologiesUsed ?? []).map(({ id }) => ({
      id,
    })),
    sectorTags: (sectorTags ?? []).map(({ id }) => ({
      id,
    })),
  };
};

interface IFormCardProps {
  index?: number;
  data: WorkExperienceItem | null;
  onSubmit: (item: UserWorkExperienceInput) => void;
  onFinish: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  "data-testid"?: string;
}

export function FormCard({
  index,
  data,
  onSubmit,
  onFinish,
  onDelete,
  onCancel,
  "data-testid": dataTestId,
}: IFormCardProps): React.ReactElement {
  const topOfFormCardRef = useRef<HTMLDivElement>(null);
  const [currentStage, setCurrentStage] = useState<FormStages>(
    FormStages.Overview
  );
  const [editing, setEditing] = useState(!data);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const isMounted = useRef(true);

  const [createSuggestionMutation] = useMutation(
    CreateWorkExperienceSuggestionDocument,
    {
      refetchQueries: [WorkExperienceSuggestionDocument],
    }
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (editing && !!data) {
      setShowPreview(true);
    } else {
      setShowPreview(false);
    }
  }, [data, editing]);

  const clickable = !editing;

  const navigateToStart = () => setCurrentStage(FormStages.Overview);

  const navigateForward = () => {
    if (currentStage === FormStages.Overview) {
      setCurrentStage(FormStages.RoleDescription);
    } else if (currentStage == FormStages.RoleDescription) {
      setCurrentStage(FormStages.CompanyDescription);
    } else if (currentStage === FormStages.CompanyDescription) {
      setEditing(false);
      onFinish();
      navigateToStart();
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      setDeleteLoading(true);
      await onDelete();
      if (isMounted.current) {
        setDeleteLoading(false);
        setEditing(false);
      }
    }
  };

  const handleEditClick = () => {
    if (editing) {
      onCancel && onCancel();
      navigateToStart();
      setEditing(false);
    } else {
      setEditing(true);
    }
  };

  const navigateToStage = (stage: FormStages) => {
    setEditing(true);
    setCurrentStage(stage);
  };

  const handleFormSubmit = (input: EditedWorkExperienceItem) => {
    setSubmitLoading(true);

    // Scroll into view if necessary
    if (
      topOfFormCardRef.current &&
      // Check header is in the viewport
      topOfFormCardRef.current.getBoundingClientRect().y < 0
    ) {
      topOfFormCardRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const formattedData = formatOutgoingData(input);
    onSubmit(formattedData);

    if (currentStage === FormStages.CompanyDescription) {
      setEditing(false);
      if (data?.id) {
        createSuggestionMutation({
          variables: { workExperienceId: data.id },
        });
      }
    }

    navigateForward();
    setSubmitLoading(false);
  };

  return (
    <>
      <FinalForm
        initialValues={data ? formatIncomingData(data) : null}
        initialValuesEqual={(oldState, newState) =>
          deepEqual(oldState, newState)
        }
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} data-testid={dataTestId ?? "form-card"}>
            <Card
              ref={topOfFormCardRef}
              clickable={clickable}
              editing={editing}
              onClick={clickable ? () => setEditing(true) : undefined}
              showPreview={showPreview}
            >
              <Spacing size={4}>
                <Spacing>
                  <TopSection editing={editing}>
                    {editing && (
                      <CloseButton
                        data-testid="edit-button"
                        type="button"
                        onClick={handleEditClick}
                      >
                        <Cancel />
                      </CloseButton>
                    )}
                  </TopSection>
                  <ComponentWrapper>
                    {editing || !data ? (
                      <EditingComponentWrapper>
                        <EditingWorkExperience
                          form={form}
                          experience={data}
                          currentStage={currentStage}
                        />
                      </EditingComponentWrapper>
                    ) : (
                      <DisplayWorkExperience
                        experience={data}
                        index={index}
                        handleEditClick={handleEditClick}
                      />
                    )}
                  </ComponentWrapper>
                </Spacing>

                {editing && (
                  <BottomSection>
                    <Button
                      level="primary"
                      type="submit"
                      disabled={submitLoading}
                    >
                      {submitLoading
                        ? "Saving..."
                        : currentStage === FormStages.CompanyDescription
                        ? "Finish"
                        : "Save and continue"}
                    </Button>
                    {onDelete && (
                      <DefaultDeleteButton
                        level="secondary"
                        type="button"
                        disabled={deleteLoading}
                        onClick={handleDelete}
                        style={{ marginRight: pxToRem(8) }}
                      >
                        {deleteLoading ? "Deleting..." : "Delete"}
                      </DefaultDeleteButton>
                    )}
                  </BottomSection>
                )}
              </Spacing>
            </Card>
            {data && showPreview && (
              <PreviewCard>
                <Spacing>
                  <Text size={-1} bold>
                    Here&apos;s a preview of how this will look on your profile
                  </Text>
                  <CardWithBorder>
                    <DisplayWorkExperience
                      experience={data}
                      index={index}
                      isPreview
                    />
                  </CardWithBorder>
                </Spacing>
              </PreviewCard>
            )}
          </form>
        )}
      />
      {data && data.id && (
        <SuggestedChanges
          workExperience={data}
          isEditing={editing}
          onAcceptSuggestion={suggestion => {
            handleFormSubmit({
              ...data,
              companyLogoPath: [{ path: data.companyLogoPath }],
              descriptions: suggestion,
            } as EditedWorkExperienceItem);
            navigateToStage(FormStages.RoleDescription);
          }}
        />
      )}
    </>
  );
}
