import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";
import { useEffect, useMemo, useState } from "react";
import { differenceInDays } from "date-fns";

import { AppModal } from "./AppModal";
import { JobHeader } from "./JobHeader";

import { palette, pxToRem } from "@otta/design-tokens";
import { Card as DefaultCard, Text } from "@otta/design";
import { Job, UserJobPreferences } from "@otta/search/schema";
import { SUPPORTED_REGIONS } from "@otta/search/constants/supportedRegions";
import { NotFound } from "@otta/search/router";
import { pushEventToDataLayer } from "@otta/analytics";

const MODAL_NAME = "20230313_GET_THE_APP_MODAL";

export enum JobCardType {
  jobDeck = "JOB_DECK",
  dashboard = "DASHBOARD",
  public = "PUBLIC",
  application = "APPLICATION",
}

const Card = styled(DefaultCard)`
  padding: 0;
  // So that card looks full even when loading
  min-height: 100vh;
  margin-bottom: 19;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: xl;
`;

export const YellowSpace = styled.div`
  ${up(
    "tablet",
    css`
      background: ${palette.brand.yellow};
      height: ${pxToRem(24)};
    `
  )}
`;

export interface JobCardProps {
  job?: Job.Fragment;
  user?: UserJobPreferences.CurrentUser;
  type: JobCardType;
  publicJob?: boolean;
  toggleKeyboardShortcuts?: (enable: boolean) => void;
}

export function JobCard({
  job,
  user,
  type,
  publicJob = false,
  toggleKeyboardShortcuts,
}: JobCardProps): React.ReactElement {
  useEffect(() => {
    if (job) {
      pushEventToDataLayer({
        event: "user-viewed-job",
        jobId: job?.id,
        jobFunction: job?.function?.value,
        jobSubFunction: job?.subFunction?.value,
        minYearsExperienceRequired: job.minYearsExperienceRequired,
        maxYearsExperience: job.maxYearsExperienceRequired,
        jobPosting: job.originalUrl ? "scraped" : "direct",
      });
    }
  }, [job]);

  const location = useLocation();

  const [showAppModal, setShowAppModal] = useState(false);
  const handleAppModalClose = () => {
    localStorage.setItem(MODAL_NAME, "true");
    setShowAppModal(false);
  };

  const isOlderThan2Days = useMemo(() => {
    const now = new Date();

    if (user?.insertedAt) {
      const daysSinceRegistered = differenceInDays(
        now,
        new Date(user?.insertedAt)
      );
      return daysSinceRegistered > 2;
    }
    return false;
  }, [user?.insertedAt]);

  const isSupportedRegion = useMemo(
    () => !!user?.region && SUPPORTED_REGIONS.includes(user?.region),
    [user?.region]
  );

  useEffect(() => {
    const { onboard = false } = (location.state ?? {}) as {
      onboard?: true;
    };

    const shownModal = localStorage.getItem(MODAL_NAME) === "true";

    const shouldShowAppModal = !shownModal && !publicJob && !onboard;

    setShowAppModal(
      shouldShowAppModal && isSupportedRegion && isOlderThan2Days
    );
  }, [isOlderThan2Days, isSupportedRegion, location, publicJob]);

  if (!job) {
    return (
      <NotFound>
        <Card>
          <ContentContainer>
            <Text align="center">Job not found</Text>
          </ContentContainer>
        </Card>
      </NotFound>
    );
  }

  const jobInvolvesSummary = job.involvesBullets
    ?.map(item => item.value)
    .join(" ")
    ?.slice(0, 50);

  const title = `${job.company.name} ${job.title} | Welcome to the Jungle (formerly Otta)`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />

        <meta
          name="description"
          content={`Apply for ${job.company.name} ${
            job.title
          } on Welcome to the Jungle (formerly Otta}. ${
            jobInvolvesSummary ?? ""
          }`}
        />

        <meta
          property="og:url"
          content={`https://app.welcometothejungle.com/jobs/${job.externalId}`}
        />
        <meta property="og:title" content={title} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
      </Helmet>
      <ContentContainer data-testid="job-card-v2">
        <JobHeader
          job={job}
          user={user}
          type={type}
          publicJob={publicJob}
          toggleKeyboardShortcuts={toggleKeyboardShortcuts}
        />
      </ContentContainer>
      {showAppModal && <AppModal handleClose={handleAppModalClose} />}
    </>
  );
}
