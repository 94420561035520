import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridColumns } from "@visx/grid";
import { Group } from "@visx/group";
import { MarkerCircle } from "@visx/marker";
import { ParentSize } from "@visx/responsive";
import { scaleLinear, scalePoint } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { useMemo } from "react";
import styled, { css, down } from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Text, VerticalSpacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useQuery } from "@otta/search/apollo";
import { ExternalLink } from "@otta/search/components/ExternalLink";
import { Lock as DefaultLock } from "@otta/search/components/Icons/Lock";
import { Link as DefaultLink } from "@otta/search/components/Link";
import { Loading } from "@otta/search/components/Loading";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import {
  Currency,
  ExperienceLevel,
  Job,
  Location,
  SalaryBenchmarksDocument,
  SalaryBenchmarksQuery,
  UserJobPreferences,
} from "@otta/search/schema";
import { currencyPrefix } from "@otta/search/utils/currencies";
import { REMOTE_LOCATIONS } from "@otta/search/utils/locations";
import { useExperiment } from "@otta/search/hooks/unleash";
import { Experiment } from "@otta/search/constants/experiments";

const SalaryNudgeContainer = styled.div`
  background-color: beige-200;
  border-radius: 4;
  display: flex;
  align-items: center;
  padding: sm 0;
  justify-content: center;
`;

const Lock = styled(DefaultLock)`
  min-width: 36px;
  width: 36px;
`;

const Link = styled(DefaultLink)`
  width: 100%;
`;

const WideScreenText = styled(Text)`
  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BenchmarksSVG = styled.svg<{ maxWidth: number; height: number }>`
  ${({ maxWidth, height }) => css`
    max-width: ${pxToRem(maxWidth)};
    height: ${pxToRem(height)};
    width: 100%;
  `}
`;

interface Level {
  name: ExperienceLevel;
  minValue: number;
  maxValue: number;
  label: string;
}

const LEVELS: Level[] = [
  {
    name: ExperienceLevel.NinePlusYears,
    minValue: 9,
    maxValue: Infinity,
    label: "Expert",
  },
  {
    name: ExperienceLevel.FiveToEightYears,
    minValue: 5,
    maxValue: 8,
    label: "Senior",
  },
  {
    name: ExperienceLevel.ThreeToFourYears,
    minValue: 3,
    maxValue: 4,
    label: "Mid",
  },
  {
    name: ExperienceLevel.OneToTwoYears,
    minValue: 1,
    maxValue: 2,
    label: "Junior",
  },
  {
    name: ExperienceLevel.EntryLevel,
    minValue: 0,
    maxValue: 0,
    label: "Entry",
  },
];

const overlappingExperienceLevels = (
  minYearsExperienceRequired: number,
  maxYearsExperienceRequired: number
) =>
  LEVELS.reduce(
    (acc, level) =>
      minYearsExperienceRequired <= level.maxValue &&
      maxYearsExperienceRequired >= level.minValue
        ? [...acc, level]
        : acc,
    [] as Level[]
  );

const formatBenchmarkDatasets = (
  experienceLevels: Level[],
  salaryBenchmarks: SalaryBenchmarksQuery,
  minSalary: string | null | undefined,
  maxSalary: string | null | undefined
): {
  datasets: Record<string, number[]>;
  hasData: boolean;
  currency: Currency;
} => {
  let hasData = false;

  const {
    firstQuartile: { datapoints: firstQuartileDatapoints, currency },
    secondQuartile: { datapoints: secondQuartileDatapoints },
    thirdQuartile: { datapoints: thirdQuartileDatapoints },
  } = salaryBenchmarks.salaryStatistics;

  const quartiles = [
    firstQuartileDatapoints,
    secondQuartileDatapoints,
    thirdQuartileDatapoints,
  ];

  const datasets = experienceLevels.reduce<Record<string, number[]>>(
    (acc, level, idx) => {
      const data = quartiles
        .map(quartile => quartile[idx]?.value)
        .filter(Boolean)
        .map(x => Math.round(x / 1000));

      if (data.length) {
        hasData = true;
        acc[level.label] = data;
      }
      return acc;
    },
    {}
  );

  if (minSalary && maxSalary) {
    datasets["This job"] = [
      Math.round(Number(minSalary) / 1000),
      Math.round(Number(maxSalary) / 1000),
    ];
  }
  return { datasets, hasData, currency };
};

interface SalaryBenchmarksProps {
  job: Pick<
    Job.Fragment,
    | "externalId"
    | "minYearsExperienceRequired"
    | "maxYearsExperienceRequired"
    | "salaryRange"
    | "subFunction"
    | "locationPreferences"
  >;
  user?: Pick<
    UserJobPreferences.CurrentUser,
    "hasSubmittedSalary" | "jobExperiencePreference"
  >;
}

function Line({
  data,
  x,
  y,
  fill = palette.brand.blue,
}: {
  data: { x: number; y: string }[];
  x: (d: { x: number; y: string }) => number;
  y: (d: { x: number; y: string }) => number;
  fill?: string;
}) {
  return (
    <>
      <LinePath
        data={data}
        x={x}
        y={y}
        stroke={palette.grayscale.shade200}
        strokeWidth={3}
      />
      {data.map(d => (
        <circle key={`${d.y}-${d.x}`} r={4} cx={x(d)} cy={y(d)} fill={fill} />
      ))}
    </>
  );
}

function Chart({
  width,
  currency,
  datasets,
}: {
  width: number;
  currency: Currency;
  datasets: Record<string, number[]>;
}) {
  const height = 90 + 25 * Object.keys(datasets).length;
  const yScale = useMemo(
    () =>
      scalePoint({
        domain: Object.keys(datasets),
        range: [0, height - 25],
        padding: 0.6,
      }),
    [datasets, height]
  );

  const domain = useMemo<[number, number]>(() => {
    const allData = Object.values(datasets).flatMap(d => d);
    const min = Math.min(...allData);
    const max = Math.max(...allData);
    return [Math.floor(min / 10) * 10 - 10, Math.ceil(max / 10) * 10 + 10];
  }, [datasets]);

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain,
        range: [0, width - 75],
      }),
    [width, domain]
  );

  return (
    <BenchmarksSVG maxWidth={width} height={height}>
      <MarkerCircle id="marker-job" fill={palette.brand.blue} size={2} />
      <Group left={50}>
        <GridColumns
          scale={xScale}
          width={width - 75}
          height={height - 25}
          stroke={palette.grayscale.shade200}
        />
        <AxisLeft
          hideTicks
          scale={yScale}
          stroke={palette.grayscale.shade200}
        />
        <AxisBottom
          hideTicks
          scale={xScale}
          numTicks={6}
          stroke={palette.grayscale.shade200}
          top={height - 25}
          tickFormat={v => `${currencyPrefix(currency)}${v.valueOf()}k`}
        />
        {Object.entries(datasets).map(([level, data], index) => (
          <Line
            key={`level-${index}`}
            fill={
              level === "This job" ? palette.brand.yellow : palette.brand.blue
            }
            data={data.map(x => ({ x, y: level }))}
            x={d => xScale(d.x)}
            y={d => yScale(d.y) ?? 0}
          />
        ))}
      </Group>
    </BenchmarksSVG>
  );
}

export default function SalaryBenchmarks({
  job,
  user,
}: SalaryBenchmarksProps): React.ReactElement | null {
  const cities = job.locationPreferences.filter(
    lp => !REMOTE_LOCATIONS.includes(lp.location)
  );

  const { variant } = useExperiment(Experiment.AllSalaryBenchmarks);

  return cities.length === 1 &&
    (cities[0].location === Location.London || variant === "variant") ? (
    <SalaryBenchmarksComponent
      job={job}
      user={user}
      location={cities[0].location}
    />
  ) : null;
}

interface SalaryBenchmarksComponentProps extends SalaryBenchmarksProps {
  location: Location;
}

function SalaryBenchmarksComponent({
  job: {
    minYearsExperienceRequired,
    maxYearsExperienceRequired,
    salaryRange,
    subFunction,
    externalId,
  },
  user,
  location,
}: SalaryBenchmarksComponentProps): React.ReactElement | null {
  const minSalary = salaryRange?.minAmount;
  const maxSalary = salaryRange?.maxAmount;

  const experienceLevels = useMemo(
    () =>
      overlappingExperienceLevels(
        minYearsExperienceRequired ?? 0,
        maxYearsExperienceRequired ?? Infinity
      ),
    [minYearsExperienceRequired, maxYearsExperienceRequired]
  );

  const showSalaryNudge =
    !!user &&
    !user.hasSubmittedSalary &&
    !(
      user.jobExperiencePreference?.entryLevel ||
      user.jobExperiencePreference?.internship
    );

  const { data: salaryBenchmarksData } = useQuery(SalaryBenchmarksDocument, {
    variables: {
      subFunctionId: subFunction?.id as string,
      experienceLevels: experienceLevels.map(({ name }) => name),
      location,
    },
    skip: !subFunction || experienceLevels.length === 0 || showSalaryNudge,
  });

  if (showSalaryNudge) {
    return (
      <Spacing>
        <TitleContainer>
          <TooltipWithIcon
            content="Submit your salary to unlock salary benchmarks for all roles"
            iconColor={palette.grayscale.shade600}
          >
            <Text as="h3" bold size={1}>
              Salary benchmarks
            </Text>
          </TooltipWithIcon>
        </TitleContainer>
        <Link
          to="/salaries/submit-data"
          state={{
            fromJobsCard: externalId,
            ukLocations: true,
          }}
          underline={false}
        >
          <SalaryNudgeContainer>
            <Lock />
            <Text>
              Unlock salary benchmarks{" "}
              <WideScreenText as="span">for all roles</WideScreenText>
            </Text>
          </SalaryNudgeContainer>
        </Link>
      </Spacing>
    );
  }

  if (!salaryBenchmarksData) {
    return (
      <div>
        <Text as="h3" bold size={1}>
          Salary benchmarks
        </Text>
        <Loading />
      </div>
    );
  }

  const { datasets, hasData, currency } = formatBenchmarkDatasets(
    experienceLevels,
    salaryBenchmarksData,
    minSalary,
    maxSalary
  );

  return hasData ? (
    <VerticalSpacing>
      <TitleContainer>
        <TooltipWithIcon
          content="This shows the 25th, 50th, and 75th percentile salaries for all jobs in this category across experience levels"
          iconColor={palette.grayscale.shade600}
        >
          <Text as="h3" bold size={1}>
            Salary benchmarks
          </Text>
        </TooltipWithIcon>
      </TitleContainer>
      {subFunction && (
        <Text bold align="left">
          {subFunction.value}
        </Text>
      )}
      <div data-testid="salary-benchmarks-chart">
        <ParentSize>
          {({ width }) => (
            <Chart width={width} currency={currency} datasets={datasets} />
          )}
        </ParentSize>
      </div>
      <ExternalLink
        newTab
        to="/salaries"
        iconLocation="right"
        $iconSize={20}
        onClick={() =>
          pushAnalyticsEvent({
            eventName: "Candidate Clicked",
            name: "  job-card-view-all-salary-benchmarks",
          })
        }
      >
        View all salary benchmarks
      </ExternalLink>
    </VerticalSpacing>
  ) : null;
}
