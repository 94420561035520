import { createContext, useContext, useMemo } from "react";

import { useQuery } from "@otta/search/apollo";
import {
  CompanyEbStatisticsFragment,
  CompanyFragment,
  CurrentUserDocument,
  JobFragment,
} from "@otta/search/schema";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";

type EBTrackingContextType = {
  page?: "apply" | "company" | "job" | "dashboard";
  companyId?: string;
  jobId?: string;
  jobFunction?: string;
  jobSubFunction?: string;
  jobValueClassification: string | null;
  minYearsExperienceRequired?: number | null;
  maxYearsExperienceRequired?: number | null;
  applyViaOtta?: boolean;
  loginStatus: boolean | null;
};

const EBTrackingContext = createContext<EBTrackingContextType | undefined>(
  undefined
);

export const EBTrackingProvider = ({
  children,
  page,
  company,
  job,
}: {
  children: React.ReactNode;
  page?: "apply" | "company" | "job" | "dashboard";
  company?: Pick<CompanyFragment, "id">;
  job?: Pick<
    JobFragment,
    | "id"
    | "function"
    | "subFunction"
    | "minYearsExperienceRequired"
    | "maxYearsExperienceRequired"
    | "acceptsInternalApplications"
  >;
}) => {
  const { data: userData, loading } = useQuery(CurrentUserDocument);

  const contextValue = useMemo(() => {
    return {
      page,
      companyId: company?.id,
      jobId: job?.id,
      jobFunction: job?.function?.value,
      jobSubFunction: job?.subFunction?.value,
      jobValueClassification: jobValueClassification(job?.function?.id),
      minYearsExperienceRequired: job?.minYearsExperienceRequired,
      maxYearsExperienceRequired: job?.maxYearsExperienceRequired,
      applyViaOtta: job?.acceptsInternalApplications,
      loginStatus: loading ? null : !!userData?.currentUser,
    };
  }, [
    page,
    company?.id,
    job?.id,
    job?.function?.value,
    job?.function?.id,
    job?.subFunction?.value,
    job?.minYearsExperienceRequired,
    job?.maxYearsExperienceRequired,
    job?.acceptsInternalApplications,
    loading,
    userData?.currentUser,
  ]);

  return (
    <EBTrackingContext.Provider value={contextValue}>
      {children}
    </EBTrackingContext.Provider>
  );
};

export const useEBTracking = () => {
  const context = useContext(EBTrackingContext);

  if (process.env.NODE_ENV === "development" && !context) {
    // eslint-disable-next-line no-console
    console.error("EBTrackingProvider must be wrapped in EBTrackingProvider");
  }

  return (context || {}) as EBTrackingContextType;
};

export function peopleBreakdownTracking(
  stats?: CompanyEbStatisticsFragment["ebStatistics"] | null
): string[] {
  const breakdown = stats?.peopleBreakdown;
  const peopleBreakdown: string[] = [];

  if (breakdown?.ethnicities?.length) {
    peopleBreakdown.push("Ethnicities");
  }

  if (breakdown?.genders?.length) {
    peopleBreakdown.push("Genders");
  }

  if (breakdown?.workingLocations?.length) {
    peopleBreakdown.push("Working Location");
  }

  return peopleBreakdown;
}

export function usePeopleBreakdownTracking(
  stats?: CompanyEbStatisticsFragment["ebStatistics"] | null
) {
  return useMemo(() => peopleBreakdownTracking(stats), [stats]);
}
