import styled, { css, up } from "@xstyled/styled-components";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import { FollowCompany } from "../../Jobs/JobCard/components/FollowCompany";
import { SectionTitle } from "../Section";

import { Button, Card, Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import {
  SpotlightContentDocument,
  Company,
  SpotlightContentReason,
  UserJobPreferencesDocument,
  UserJobPreferences,
} from "@otta/search/schema";
import { useQuery } from "@otta/search/apollo";
import { Asset } from "@otta/search/components/AssetSprinkler/Asset";
import { pushAnalyticsEvent } from "@otta/analytics";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StaticContainer = styled(Column)`
  background: ${palette.brand.white};
  flex-shrink: 0;
  padding: lg;

  ${up(
    "tablet",
    css`
      justify-content: space-between;
      padding: xl;
      width: ${pxToRem(340)};
    `
  )};
`;

const MobileContainer = styled(Column)`
  border-radius: 4;
  overflow: hidden;
`;

const AssetContainer = styled.div`
  padding: xl;
  background: ${palette.grayscale.shade50};
  flex: 1;
`;

const CompanyLogoWrapper = styled.div`
  flex: 0 0 60px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
`;

const CompanyLogo = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled(Row)`
  align-items: center;
  gap: md;
`;

const LinkButton = styled(Button).attrs({ level: "secondary" })`
  min-height: ${pxToRem(44)};
  font-size: ${pxToRem(16)};
  padding: sm xl sm xl;
  gap: 10;
  margin-top: md;
  white-space: nowrap;
`;

function StaticSection({
  company,
  reason,
  value,
  user,
}: {
  isMobile?: boolean;
  company: Pick<Company, "id" | "name" | "faviconPath" | "urlSafeName">;
  reason: SpotlightContentReason | null;
  value: string | null;
  user?:
    | Pick<UserJobPreferences.CurrentUser, "followedCompanies">
    | undefined
    | null;
}) {
  const isQualityReason = reason && value === "QUALITY";
  return (
    <StaticContainer>
      <Column style={{ gap: 12 }}>
        <Row style={{ alignItems: "center" }}>
          {company.faviconPath && (
            <CompanyLogoWrapper>
              <CompanyLogo src={company.faviconPath} />
            </CompanyLogoWrapper>
          )}
          <Text bold>{company.name}</Text>
        </Row>
        {isQualityReason ? (
          <div>
            {`Hear what the team has to say about ${value.toLowerCase()}, view their
          company profile to find out more and follow them to be the first one
          to hear about new jobs.`}
          </div>
        ) : (
          <div>
            Hear what the team has to say, view their company profile to find
            out more and follow them to be the first one to hear about new jobs.
          </div>
        )}
      </Column>
      <ButtonWrapper>
        <FollowCompany
          company={company}
          user={user}
          data-analytics-id="dashboard-spotlight-follow-company"
        />
        <LinkButton
          as={Link}
          to={`/companies/${company.urlSafeName}`}
          data-analytics-id="dashboard-spotlight-view-profile"
        >
          View profile
        </LinkButton>
      </ButtonWrapper>
    </StaticContainer>
  );
}

function ReasonSection({
  reason,
  value,
}: {
  reason: SpotlightContentReason;
  value: string;
}) {
  return (
    <Card
      style={{
        backgroundColor: palette.extended.green.shade200,
        flex: 0,
      }}
    >
      <Column style={{ justifyContent: "space-between", height: "100%" }}>
        {reason === "QUALITY" ? (
          <Text>
            You told us that <Text bold>{value}</Text> is important for you in
            your next role
          </Text>
        ) : (
          <Text>
            You told us that you want to work in <Text bold>{value}</Text> for
            your next role
          </Text>
        )}
        <LinkButton
          as={Link}
          to={`/preferences`}
          data-analytics-id="dashboard-spotlight-update-preferences"
        >
          Update preferences
        </LinkButton>
      </Column>
    </Card>
  );
}

export function SpotlightContent({
  companyIds,
  isMobile = true,
}: {
  companyIds: string[];
  isMobile?: boolean;
}) {
  const { data, loading } = useQuery(SpotlightContentDocument, {
    variables: { companyIds },
  });

  const { data: userData, loading: userLoading } = useQuery(
    UserJobPreferencesDocument
  );

  const spotlightContent = data?.currentUser?.spotlightContent;

  useEffect(() => {
    if (spotlightContent && spotlightContent.link) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Spotlight Dashboard Content",
        companyId: spotlightContent.company.id,
      });
    }
  }, [spotlightContent]);

  if (loading || !spotlightContent || !spotlightContent.link || userLoading) {
    return null;
  }

  return (
    <Spacing size={1}>
      <SectionTitle bold as="h3">
        This company might be a good match
      </SectionTitle>
      <div data-testid="spotlight-content-card">
        {isMobile ? (
          <Column style={{ gap: 8 }}>
            <MobileContainer>
              <StaticSection
                company={spotlightContent.company}
                reason={spotlightContent.reason}
                value={spotlightContent.value}
                user={userData?.currentUser}
              />
              <AssetContainer>
                <Asset
                  asset={spotlightContent.link}
                  data-testid="brand-asset-video"
                />
              </AssetContainer>
            </MobileContainer>
            {spotlightContent.reason && spotlightContent.value && (
              <ReasonSection
                reason={spotlightContent.reason}
                value={spotlightContent.value}
              />
            )}
          </Column>
        ) : (
          <Row style={{ gap: 12 }}>
            <Card
              style={{
                position: "relative",
                overflow: "hidden",
                padding: 0,
                background: palette.grayscale.shade50,
                flex: 1,
              }}
            >
              <Row>
                <StaticSection
                  company={spotlightContent.company}
                  reason={spotlightContent.reason}
                  value={spotlightContent.value}
                  user={userData?.currentUser}
                />
                <AssetContainer>
                  <Asset
                    asset={spotlightContent.link}
                    data-testid="brand-asset-video"
                  />
                </AssetContainer>
              </Row>
            </Card>
            {spotlightContent.reason && spotlightContent.value && (
              <ReasonSection
                reason={spotlightContent.reason}
                value={spotlightContent.value}
              />
            )}
          </Row>
        )}
      </div>
    </Spacing>
  );
}
