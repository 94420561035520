import styled, { css, down, up } from "@xstyled/styled-components";

import { NavLinks } from "../NavLinks";
import { JobCardContext } from "../JobHeader";

import { JobSection } from "./JobSection";
import { CompanySection } from "./CompanySection";

import { pxToRem, borderRadius, palette } from "@otta/design-tokens";
import { CompanyWorkflowStatus } from "@otta/search/schema";

const Top = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  flex-direction: column;
  transition: default;
  transition-property: height;
  width: 100%;

  ${up(
    "tablet",
    css`
      background: ${palette.brand.yellow};
    `
  )}
`;

const PaddedTopCard = styled.div`
  align-self: stretch;
  ${up(
    "tablet",
    css`
      padding: 0 1.5rem;
    `
  )}
`;

const TopCard = styled.div`
  background: ${palette.brand.white};
  margin: auto;

  ${up(
    "tablet",
    css`
      max-width: ${pxToRem(1024)};
      border-radius: ${pxToRem(borderRadius)};
      margin-bottom: xl;
    `
  )}
`;

const VerticalSeperator = styled.div`
  > div {
    padding-top: lg;
    padding-bottom: lg;
    border-bottom: 1px solid ${palette.grayscale.shade200};
  }
  > div:first-child {
    padding-top: unset;
  }
  > div:last-child {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

const VerticalSeparatorMobileOnly = styled(VerticalSeperator)`
  ${up(
    "tablet",
    css`
      > div {
        border-bottom: unset;
        padding-bottom: unset;
        padding-top: unset;
      }
    `
  )}
`;

const TopCardMainContent = styled(VerticalSeparatorMobileOnly)`
  display: flex;
  justify-content: space-between;
  max-width: ${pxToRem(1024)};
  padding: xl;
  flex-direction: column;

  ${up(
    "tablet",
    css`
      gap: xl;
      flex-direction: row;
    `
  )}
`;

const TopSeparator = styled.div`
  border-right: 1px solid ${palette.grayscale.shade200};
  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

export const TopContent = ({
  job,
  user,
}: Omit<
  JobCardContext,
  "expandOtherJobs" | "otherJobsExpanded"
>): React.ReactElement => {
  return (
    <Top>
      <PaddedTopCard>
        <TopCard>
          <TopCardMainContent>
            <JobSection job={job} user={user} />
            <TopSeparator />
            <CompanySection job={job} user={user} />
          </TopCardMainContent>
        </TopCard>
      </PaddedTopCard>
      {job.company.workflowStatus !== CompanyWorkflowStatus.CompletedByUser && (
        <NavLinks />
      )}
    </Top>
  );
};
